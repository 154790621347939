import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo.png';

        const useStyles = makeStyles({
            title: {
                flex: 1,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            spacer: {
                flex: 1,
            },
            contentWithSidebar: {
                display: 'flex',
                flexGrow: 1,
            }
        });
        //style={{ background: '#FFFFFF' }}
        const MyAppBar = props => {
            const classes = useStyles();
            return (
                <div>
                    <AppBar {...props} >
                        <Typography
                            variant="h6"
                            color="white"
                            className={classes.title}
                            id="react-admin-title"
                        />
                        <img src={logo} style={{ width: "120px" }} />
                        <span className={classes.spacer} />
                    </AppBar>
                </div>
            );
        };

export default MyAppBar;

