import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    useQuery,
    Loading,
    Error,
    List,
    FormWithRedirect, 
    ExportButton, 
    RefreshButton, 
    SimpleList,
    CardActions, 
    SimpleShowLayout, 
    Show, Datagrid, 
    required, 
    TextField, 
    EditButton, 
    ReferenceInput, 
    SelectInput, 
    TextInput, 
    Edit, 
    SimpleForm,
    Create, 
    BooleanInput,
    Filter } from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const CustomerActions = ({
    currentSort,
    exporter,
    filterValues,
    resource,
    total
}) => (
        <CardActions>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
            <RefreshButton />
        </CardActions>
    );

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Post details</Typography>
        <Typography variant="body1">
            Posts will only be published one an editor approves them
        </Typography>
    </div>
);


const CustomerTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

const GetNlp = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'NLP',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <SelectInput source="nlp" choices={tData} fullWidth/>
    )
};

const GetMongo = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'Mongo',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    
    return (
        <SelectInput source="mongodb" choices={tData} fullWidth/>
    )
};

const GetElastic = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'Elastic',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <SelectInput source="elasticSearch" choices={tData} fullWidth/>
    )
};

const GetRedis = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'Redis',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <SelectInput source="redis" choices={tData} fullWidth/>
    )
};

const GetCloud = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'cloud',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <SelectInput source="cloudServer" choices={tData} fullWidth/>
    )
};

const GetMediaServer = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'MediaServer',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <SelectInput source="mediaServer" choices={tData} fullWidth/>
    )
};

export const CustomerList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<CustomerFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.email}
                />
            ) : (
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="email" />
                        <TextField source="phone" />
                        <EditButton />
                    </Datagrid>
                )}
        </List>
    )
};

function renderBox(name){
    return (
        <Box
        flex={1}
        mr={{ xs: 0, lg: '1em' }}
    >
        <TextInput source={name} validate={required()} fullWidth />
    </Box>
    );
}

function renderSelectBox(func){
    return (
        <Box
        flex={1}
        mr={{ xs: 0, lg: '1em' }}
    >
        {func()}
    </Box>
    );
}


export const CustomerEdit = props => (
    <Edit title={<CustomerTitle />} aside={<Aside />} actions={<CustomerActions />} {...props}>
    <SimpleForm>
            <Card style={{width:'100%'}}>
                <form>
                    <CardContent>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                <TextInput source="name" validate={required()} fullWidth />
                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("email")}
                                    {renderBox("phone")}
                                    {renderBox("license")}
                                </Box> 

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("ContactName")}
                                    {renderBox("ContactEmail")}
                                    {renderBox("ContactPhone")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("description")}
                                    {renderBox("webAccessDomain")}
                                    {renderBox("apiServerNginxRoute")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("apiIP")}
                                    {renderBox("audioBroadcast")}
                                    {renderBox("stanford")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderSelectBox(GetElastic)}
                                    {renderSelectBox(GetMongo)}
                                    {renderSelectBox(GetNlp)}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderSelectBox(GetMediaServer)}
                                    {renderSelectBox(GetCloud)}
                                    {renderSelectBox(GetRedis)}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("carrierOutbound")}
                                    {renderBox("carrierInbound")}
                                    {renderBox("childProcess")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("carrierData.username")}
                                    {renderBox("carrierData.password")}
                                    {renderBox("carrierData.domain")}
                                </Box>
                                <TextInput source="carrierData.port" validate={required()} fullWidth />                                                   
                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    <Box flex={1} mr={{ xs: 0, lg: '1em' }}>
                                        <BooleanInput label="isCloud" source="isCloud" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </form>
            </Card>
   
    </SimpleForm>
</Edit>
);

const CustomerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn style={{marginTop:"20px"}}/>
        <ReferenceInput label="Customer Name" source="name" reference="Customer" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const CustomerCreate = props => (
 
    <Create title={<CustomerTitle />} aside={<Aside />} actions={<CustomerActions />} {...props}>
      <SimpleForm>
    
            <Card style={{width:'100%'}}>
                <form>
                    <CardContent>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                <TextInput source="name" validate={required()} fullWidth />
                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("email")}
                                    {renderBox("phone")}
                                    {renderBox("license")}
                                </Box> 

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("ContactName")}
                                    {renderBox("ContactEmail")}
                                    {renderBox("ContactPhone")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("description")}
                                    {renderBox("webAccessDomain")}
                                    {renderBox("apiServerNginxRoute")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("apiIP")}
                                    {renderBox("audioBroadcast")}
                                    {renderBox("stanford")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderSelectBox(GetElastic)}
                                    {renderSelectBox(GetMongo)}
                                    {renderSelectBox(GetNlp)}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderSelectBox(GetMediaServer)}
                                    {renderSelectBox(GetCloud)}
                                    {renderSelectBox(GetRedis)}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("carrierOutbound")}
                                    {renderBox("carrierInbound")}
                                    {renderBox("childProcess")}
                                </Box>

                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("carrierData.username")}
                                    {renderBox("carrierData.password")}
                                    {renderBox("carrierData.domain")}
                                </Box>
                                <TextInput source="carrierData.port" validate={required()} fullWidth />                                                   
                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    <Box flex={1} mr={{ xs: 0, lg: '1em' }}>
                                        <BooleanInput label="isCloud" source="isCloud" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </form>
            </Card>
 
    </SimpleForm>
    </Create>
)