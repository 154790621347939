import React from 'react';
import Card from '@material-ui/core/Card';
import DnsIcon from '@material-ui/icons/Dns';
import UserIcon from '@material-ui/icons/Group';
import Typography from '@material-ui/core/Typography';
import {useQuery, Loading, Error} from 'react-admin';
import PropTypes from 'prop-types';
import PostIcon from '@material-ui/icons/Book';
import { withStyles } from '@material-ui/core/styles';
import GridList from "@material-ui/core/GridList";
import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const GetNlp = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'NLP',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <Typography variant="h5" component="h2">
            {tData.length}
        </Typography>
    )
};

const GetCustomer = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'Customer',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <Typography variant="h5" component="h2">
            {tData.length}
        </Typography>
    )
};

const GetMediaServer = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'MediaServer',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.id,'name':e.name});
    })
    return (
        <Typography variant="h5" component="h2">
            {tData.length}
        </Typography>
    )
};

function renderCard(classes,icon,title,Funcvalue,color) {
    return (
        <div>
            <CardIcon Icon={icon} bgColor={color} />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {title}
            </Typography>
                <Typography variant="h5" component="h2">
                    {Funcvalue()}
            </Typography>
            </Card>
        </div>
    );
}

function DashboardCard(props) {
    const { classes } = props;
    return (
        <GridList cols={3}>
            <div className={classes.main}>
                {renderCard(classes,PostIcon,'NLP',GetNlp,"#31708f")}
            </div>
            <div className={classes.main}>
                {renderCard(classes,UserIcon,'Customers',GetCustomer,"#ff9800")}
            </div>
            <div className={classes.main}>
                {renderCard(classes,DnsIcon,'Media Server',GetMediaServer,"#f44336")}
            </div>
        </GridList>
    );
};

DashboardCard.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DashboardCard);