import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    List,
    SimpleList,
    Datagrid,  
    TextField, 
    EditButton, 
    ReferenceInput, 
    SelectInput, 
    TextInput, 
    Filter } from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';


export const OnlyViewPhoneNumbersList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (

        <List filters={<OnlyViewPhoneNumbersFilter />} {...props}>
            
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.IP}
                    tertiaryText={record => record.Port}
                />
            ) : (
                    <Datagrid>
                        <TextField source="phone" />
                        <TextField source="customerName" />
                        <TextField source="carrierName" />
                    </Datagrid>
                )}
        </List>
    )
};

const OnlyViewPhoneNumbersTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

const OnlyViewPhoneNumbersFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn style={{marginTop:"20px"}}/>
        <ReferenceInput label="NLP Name" source="name" reference="NLP" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Phone Number" source="phone" reference="OnlyViewPhoneNumbers" allowEmpty>
            <SelectInput optionText="phone" />
        </ReferenceInput>
    </Filter>
);
