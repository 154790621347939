import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    List,
    FormWithRedirect, 
    ExportButton, 
    RefreshButton, 
    SimpleList,
    CardActions, 
    SimpleShowLayout, 
    Show, Datagrid, 
    required, 
    TextField, 
    EditButton, 
    ReferenceInput, 
    SelectInput, 
    TextInput, 
    Edit, 
    SimpleForm,
    Create, 
    Filter } from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
const NLPActions = ({
    currentSort,
    exporter,
    filterValues,
    resource,
    total
}) => (
        <CardActions>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
            <RefreshButton />
        </CardActions>
    );

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Post details</Typography>
        <Typography variant="body1">
            Posts will only be published one an editor approves them
        </Typography>
    </div>
);

export const NLPList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (

        <List filters={<NLPFilter />} {...props}>
            
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.IP}
                    tertiaryText={record => record.Port}
                />
            ) : (
                    <Datagrid>
                        <TextField source="_id" />
                        <TextField source="name" />
                        <TextField source="IP" />
                        <TextField source="Port" />
                        <EditButton />
                    </Datagrid>
                )}
        </List>
    )
};

const PostTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

function renderBox(name){
    console.log(name)
    return (
        <Box
        flex={1}
        mr={{ xs: 0, lg: '1em' }}
    >
        <TextInput source={name} validate={required()} fullWidth />
    </Box>
    );
}

const redirect = (basePath, id, data) => `/NLP`;


// /redirect={redirect}
export const NLPEdit = (props) => (
    <Edit title={<PostTitle />} aside={<Aside />} actions={<NLPActions />} {...props}>
        <SimpleForm>
                <Card style={{width:'100%'}}>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <SimpleForm> */}
                                    <TextInput source="name" validate={required()} fullWidth />
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("IP")}
                                        {renderBox("Port")}
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </form>
                </Card>
        </SimpleForm>
    </Edit>
);


export const NLPShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="IP" />
            <TextField source="Port" />
        </SimpleShowLayout>
    </Show>
);

const NLPFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn style={{marginTop:"20px"}}/>
        <ReferenceInput label="NLP Name" source="name" reference="NLP" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const NLPCreate = props => (
    <Create {...props}>
        <SimpleForm>
      
                <Card style={{width:'100%'}}>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <SimpleForm> */}
                                    <TextInput source="name" validate={required()} fullWidth />
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("IP")}
                                        {renderBox("Port")}
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </form>
                </Card>
          
        </SimpleForm>
    </Create>
);