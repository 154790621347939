import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    List,
    FormWithRedirect, 
    ExportButton, 
    RefreshButton, 
    SimpleList,
    CardActions, 
    SimpleShowLayout, 
    Show, Datagrid, 
    required, 
    TextField, 
    EditButton, 
    ReferenceInput, 
    SelectInput, 
    TextInput, 
    Edit, 
    SimpleForm,
    Create, 
    Filter } from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const MongoActions = ({
    currentSort,
    exporter,
    filterValues,
    resource,
    total
}) => (
        <CardActions>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
            <RefreshButton />
        </CardActions>
    );

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Post details</Typography>
        <Typography variant="body1">
            Posts will only be published one an editor approves them
        </Typography>
    </div>
);

function renderBox(name){
    return (
        <Box
        flex={1}
        mr={{ xs: 0, lg: '1em' }}
    >
        <TextInput source={name} validate={required()} fullWidth />
    </Box>
    );
}

const MongoTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

export const MongoList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<MongoFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.IP}
                    tertiaryText={record => record.Port}
                />
            ) : (
                    <Datagrid>
                        <TextField source="_id" />
                        <TextField source="name" />
                        <TextField source="IP" />
                        <TextField source="Port" />
                        <EditButton />
                    </Datagrid>
                )}
        </List>
    )
};

export const MongoEdit = props => (
    <Edit title={<MongoTitle />} aside={<Aside />} actions={<MongoActions />} {...props}>
    <SimpleForm>
            <Card style={{width:'100%'}}>
                <form>
                    <CardContent>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                {/* <SimpleForm> */}
                                <TextInput source="name" validate={required()} fullWidth />
                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("IP")}
                                    {renderBox("Port")}
                                </Box>                                                    
                            </Box>
                        </Box>
                    </CardContent>
                </form>
            </Card>
    </SimpleForm>
</Edit>
);

const MongoFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn style={{marginTop:"20px"}}/>
        <ReferenceInput label="Mongo Name" source="name" reference="Mongo" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const MongoCreate = props => (
    <Create title={<MongoTitle />} aside={<Aside />} actions={<MongoActions />} {...props}>
     <SimpleForm>
            <Card style={{width:'100%'}}>
                <form>
                    <CardContent>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                {/* <SimpleForm> */}
                                <TextInput source="name" validate={required()} fullWidth />
                                <Box display={{ xs: 'block', lg: 'flex' }}>
                                    {renderBox("IP")}
                                    {renderBox("Port")}
                                </Box>                                                    
                            </Box>
                        </Box>
                    </CardContent>
                </form>
            </Card>
        </SimpleForm>
    </Create>
);