import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    FormWithRedirect,
    ExportButton,
    RefreshButton,
    SimpleList,
    CardActions,
    SimpleShowLayout,
    Show, Datagrid,
    required,
    TextField,
    EditButton,
    ReferenceInput,
    SelectInput,
    TextInput,
    Edit,
    SimpleForm,
    Create,
    Filter
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
export const MediaList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<MediaFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.IP}
                    tertiaryText={record => record.Port}
                />
            ) : (
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="fs_IP" />
                        <TextField source="fs_Port" />
                        <EditButton />
                    </Datagrid>
                )}
        </List>
    )
};

const MediaServerActions = ({
    currentSort,
    exporter,
    filterValues,
    resource,
    total
}) => (
        <CardActions>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
            <RefreshButton />
        </CardActions>
    );


const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Post details</Typography>
        <Typography variant="body1">
            Posts will only be published one an editor approves them
        </Typography>
    </div>
);

const MediaServerTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

function renderBox(name){
    return (
        <Box
        flex={1}
        mr={{ xs: 0, lg: '1em' }}
    >
        <TextInput source={name} validate={required()} fullWidth />
    </Box>
    );
}

export const MediaEdit = props => (
    <Edit title={<MediaServerTitle />} aside={<Aside />} actions={<MediaServerActions />} {...props}>
        <SimpleForm>
    
                <Card style={{width:'100%'}}>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <SimpleForm> */}
                                    <TextInput source="name" validate={required()} fullWidth />

                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("fs_IP")}
                                        {renderBox("fs_Port")}
                                        {renderBox("password")}
                                    </Box>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("recordingPath")}
                                        {renderBox("audioStreamPort")}
                                        {renderBox("VoIPProfileName")}
                                    </Box>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("asrManagerIP")}
                                        {renderBox("audioStreamURL")}
                                        {renderBox("WsProxyServerPort")}
                                    </Box>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("SIP_User")}
                                        {renderBox("SIP_IP")}
                                        {renderBox("SIP_PORT")}
                                    </Box>
                                    <TextInput source="outboundProxy" validate={required()} fullWidth />
                                </Box>
                            </Box>
                        </CardContent>
                    </form>
                </Card>
            
        </SimpleForm>
    </Edit>
);

const MediaFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn style={{marginTop:"20px"}}/>
        <ReferenceInput label="MediaServer Name" source="name" reference="Media" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const MediaCreate = props => (
<Create title={<MediaServerTitle />} aside={<Aside />} actions={<MediaServerActions />} {...props}>
<SimpleForm>
        
                <Card style={{width:'100%'}}>
                    <form>
                        <CardContent >
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <SimpleForm> */}
                                    <TextInput source="name" validate={required()} fullWidth />

                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("fs_IP")}
                                        {renderBox("fs_Port")}
                                        {renderBox("password")}
                                    </Box>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("recordingPath")}
                                        {renderBox("audioStreamPort")}
                                        {renderBox("VoIPProfileName")}
                                    </Box>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("asrManagerIP")}
                                        {renderBox("audioStreamURL")}
                                        {renderBox("WsProxyServerPort")}
                                    </Box>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderBox("SIP_User")}
                                        {renderBox("SIP_IP")}
                                        {renderBox("SIP_PORT")}
                                    </Box>
                                    <TextInput source="outboundProxy" validate={required()} fullWidth />
                                </Box>
                            </Box>
                        </CardContent>
                    </form>
                </Card>
            
        </SimpleForm>
    </Create>
);