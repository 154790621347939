import React from 'react';
import { Admin, Resource} from 'react-admin';
import { NLPList, NLPEdit, NLPCreate,NLPShow } from './nlp';
import { MongoList, MongoEdit, MongoCreate } from './mongo';
import { MediaList, MediaEdit, MediaCreate } from './mediaServer';
import { ElasticList, ElasticEdit, ElasticCreate } from './elastic';
import { CarrierList, CarrierEdit, CarrierCreate } from './carrier';
import { CustomerList, CustomerEdit, CustomerCreate } from './customer';
import { CloudList, CloudEdit, CloudCreate } from './cloud';
import { RedisList, RedisEdit, RedisCreate } from './redis';
import { PhoneMappingList, PhoneMappingEdit, PhoneMappingCreate} from './PhoneNumberMapping';
import {OnlyViewPhoneNumbersList} from './OnlyViewPhoneNumbers';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import CustomDataProvider from './CustomDataProvider';
import StorageIcon from '@material-ui/icons/Storage';
import DnsIcon from '@material-ui/icons/Dns';
import EcoIcon from '@material-ui/icons/Eco';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import CloudIcon from '@material-ui/icons/Cloud';
import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import MyLayout from './MyLayout';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
const theme = createMuiTheme({
  palette: {
    background: {
      default: "#DEE4E7"
    },
    primary: {
      type: 'light',
      main: '#5629B6'
    },
    secondary: deepPurple
  }
});
const App = () => (
  <Admin layout={MyLayout} theme={theme} dashboard={Dashboard} dataProvider={CustomDataProvider} authProvider={authProvider}>
    <Resource name="NLP" list={NLPList} edit={NLPEdit} create={NLPCreate} icon={PostIcon}/>
    <Resource name="MediaServer" list={MediaList} edit={MediaEdit} create={MediaCreate} icon={DnsIcon}/>
    <Resource name="Mongo" list={MongoList} edit={MongoEdit} create={MongoCreate} icon={EcoIcon}/>
    <Resource name="Elastic" list={ElasticList} edit={ElasticEdit} create={ElasticCreate} icon={CloudIcon}/>
    <Resource name="Customer" list={CustomerList} edit={CustomerEdit} create={CustomerCreate} icon={UserIcon}/>
    <Resource name="cloud" list={CloudList} edit={CloudEdit} create={CloudCreate} icon={SettingsSystemDaydreamIcon}/>
    <Resource name="Redis" list={RedisList} edit={RedisEdit} create={RedisCreate} icon={StorageIcon}/>
    <Resource name="Carrier" list={CarrierList} edit={CarrierEdit} create={CarrierCreate} icon={PhoneCallbackIcon}/>
    <Resource name="PhoneMapping" list={PhoneMappingList} edit={PhoneMappingEdit} create={PhoneMappingCreate} icon={PhonelinkSetupIcon}/>
    <Resource name="OnlyViewPhoneNumbers" list={OnlyViewPhoneNumbersList} icon={PhoneIphoneIcon}/> 
  </Admin>
);

export default App;