import React, { useState }  from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    useQuery,
    Loading,
    Error,
    List,
    FormWithRedirect, 
    ExportButton, 
    RefreshButton, 
    SimpleList,
    CardActions, 
    SimpleShowLayout, 
    Show, Datagrid, 
    required, 
    TextField, 
    EditButton, 
    ReferenceInput, 
    SelectInput, 
    SelectArrayInput,
    TextInput, 
    Edit, 
    SimpleForm,
    Create, 
    Filter } from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
function renderSelectBox(func, props){
    if(props && props.id){
    return (
        <Box
        flex={1}
        mr={{ xs: 0, lg: '1em' }}
    >
        {func(props.id)}
    </Box>
    );
    } else {
        return (
            <Box
            flex={1}
            mr={{ xs: 0, lg: '1em' }}
        >
            {func()}
        </Box>
        );
    }
}

// const GetCarrierPhone = (cid) => {
//     console.log(cid)
//     const { data, loading, error } = useQuery({ 
//         type: 'getOne',
//         resource: 'Carrier',
//         payload:{id:cid}
//     });
    
//     if (loading) return <Loading />;
//     if (error) return <Error />;
//     if (!data) return null;
//     let pData = [];
//     data.forEach(e=>{ 
//         e.phoneNumber.forEach(el=>{
//             pData.push({'id':el,'name':el})    
//         });
//     })
    
//     return (
//         <div>
//         <SelectArrayInput source="carrierPhone" choices={pData} fullWidth/>
//         </div>
//     )
// };


const GetCarrierName = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'Carrier',
        payload:{dropDown:true}
    });
    const [hidden, setHidden] = useState(false);
    const [phoneNumberArray, setphoneNumberArray] = useState([]);
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    let pData = [];
    data.forEach(e=>{ 
        tData.push({'id':e.name,'name':e.name,'_id':e.id,"phoneNumber":e.phoneNumber});
    })
    
    return (
        <div>
            <SelectInput source="carrierName" choices={tData} onChange={
                (elm)=>{
                    console.log(elm.target.value);
                     setphoneNumberArray([]);
                    pData = [];
                    tData.forEach(e=>{
                        if(e.name === elm.target.value) {
                            e.phoneNumber.forEach(el=>{
                                pData.push({'id':el,'name':el})    
                            });
                            setHidden(true)
                            setphoneNumberArray(pData)
                        }
                    });
                }} fullWidth/>  
                {hidden && phoneNumberArray &&  <SelectArrayInput source="carrierPhone" choices={phoneNumberArray} fullWidth/>} 
        </div>
    )
};

const GetCustomer = () => {
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'Customer',
        payload:{}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.forEach(e=>{
        tData.push({'id':e.name,'name':e.name});
    })
    return (
        <SelectInput source="customerName" choices={tData} fullWidth/>
    )
};

const GetCarrierFields = (carrierId) => {
    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: 'PhoneMapping',
        payload:{id:carrierId}
    });
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    let tData = [];
    data.data.forEach(e=>{    
        e.carrierPhone.forEach(el=>{
            tData.push({'id':el,'name':el})    
        });
    })
    return (
        <div>
        <SelectArrayInput source="carrierPhone" choices={tData} fullWidth/>
        </div>
    )
};
const PhoneMappingActions = ({
    currentSort,
    exporter,
    filterValues,
    resource,
    total
}) => (
        <CardActions>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
            <RefreshButton />
        </CardActions>
    );

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Post details</Typography>
        <Typography variant="body1">
            Posts will only be published one an editor approves them
        </Typography>
    </div>
);

export const PhoneMappingList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (

        <List filters={<PhoneMappingFilter />} {...props}>
            
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.IP}
                    tertiaryText={record => record.Port}
                />
            ) : (
                    <Datagrid>
                        <TextField source="carrierName" />
                        <TextField source="customerName" />
                        <TextField source="carrierPhone" />
                        <TextField source="createTimestamp" />
                        <TextField source="updateTimestamp" />
                        <EditButton />
                    </Datagrid>
                )}
        </List>
    )
};

const PostTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};


const redirect = (basePath, id, data) => `/phoneMapping`;

// /redirect={redirect}
export const PhoneMappingEdit = (props) => (
    <Edit title={<PostTitle />} aside={<Aside />} actions={<PhoneMappingActions />} {...props}>
        <SimpleForm>
                <Card style={{width:'100%'}}>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <SimpleForm> */}
                                    <TextInput source="carrierName" disabled={true}/>
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderSelectBox(GetCarrierFields,props)}
                                        {renderSelectBox(GetCustomer)}
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </form>
                </Card>
        </SimpleForm>
    </Edit>
);


export const PhoneMappingShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="IP" />
            <TextField source="Port" />
        </SimpleShowLayout>
    </Show>
);

const PhoneMappingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn style={{marginTop:"20px"}}/>
        <ReferenceInput label="phoneMapping Name" source="name" reference="phoneMapping" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const PhoneMappingCreate = props => (
    <Create {...props}>
        <SimpleForm>
      
                <Card style={{width:'100%'}}>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <SimpleForm> */}
                                    <Box display={{ xs: 'block', lg: 'flex' }}>
                                        {renderSelectBox(GetCarrierName)}
                                        {/* {renderSelectBox(GetCarrierPhone)} */}
                                        {renderSelectBox(GetCustomer)}
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </form>
                </Card>
          
        </SimpleForm>
    </Create>
);