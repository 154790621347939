// import { getAllByAltText } from '@testing-library/react';
// require('dotenv').config()
// const { BaseURL } = process.env;
const BaseURL = "https://cloud.slashrtc.ai/cloudManager";
// import { fetchUtils } from 'react-admin';
// import { stringify } from 'query-string';
//const apiUrl = 'https://my.api.com/';
//const httpClient = fetchUtils.fetchJson;
var uuid = require('uuid');

function getAll(suburl,url) {
    if(suburl === 'cloud') {
        url = 'cloudMachine'
    }
    if(suburl === "OnlyViewPhoneNumbers") {
        suburl = 'PhMapping' 
        url = 'PhoneCustomerMapping'
    }
    return new Promise((resolve, reject) => {
        fetch(`${BaseURL}/${suburl}/view${url}`)
            .then(res => { return res.json() })
            .then((data) => {
                console.log(data);
                if (data) {
                    let tData = [];
                    data.data.forEach(e => {
                        e.id = e._id;
                        tData.push(e);
                    });

                    resolve({
                        data: tData,
                        total: tData.length
                    })

                } else {
                    reject({
                        data: {}
                    })
                }
            }).catch((e) => {
                reject({
                    data: {}
                })
            });
    });
}

function createAll(suburl,url,Parambody) {
    if(suburl === 'cloud') {
        url = 'cloudMachine'
    }
    console.log(Parambody)
    return new Promise((resolve, reject) => {
        fetch(`${BaseURL}/${suburl}/add${url}`, {
                    method: 'post',
                    body: JSON.stringify(Parambody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => { return res.json() })
                    .then((data) => {
                        console.log(data);
                        if (data) {
                            resolve({
                                data: data
                            })
                        } else {
                            reject({
                                data: {}
                            })
                        }
                    }).catch((e) => {
                        reject({
                            data: {}
                        })
                    });
    });
}

function updateAll(suburl, url, Parambody) {
    if(suburl === 'cloud') {
        url = 'cloudMachine'
    }
    console.log(Parambody)
    return new Promise((resolve, reject) => {
        fetch(`${BaseURL}/${suburl}/update${url}`, {
                    method: 'post',
                    body: JSON.stringify(Parambody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => { return res.json() })
                    .then((data) => {
                        console.log(data);
                        if (data) {
                            resolve({
                                data: data

                            })
                        } else {
                            reject({
                                data: {}
                            })
                        }
                    }).catch((e) => {
                        reject({
                            data: {}
                        })
                    });
    });
}

function viewOneAll(suburl, url, Parambody) {
    if(suburl === 'cloud') {
        url = 'cloudMachine'
    }
    console.log(Parambody)
    return new Promise((resolve, reject) => {
        fetch(`${BaseURL}/${suburl}/view${url}ById`, {
                    method: 'post',
                    body: JSON.stringify(Parambody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => { return res.json() })
                    .then((data) => {
                        console.log(data);
                        if (data) {
                            resolve({
                                data: data

                            })
                        } else {
                            reject({
                                data: {}
                            })
                        }
                    }).catch((e) => {
                        reject({
                            data: {}
                        })
                    });
    });
}

function deleteAll(suburl, url, params) {
    if(suburl === 'cloud') {
        url = 'cloudMachine'
    }
    return new Promise((resolve, reject) => {
        fetch(`${BaseURL}/${suburl}/remove${url}`, {
                    method: 'post',
                    body: JSON.stringify({_id:params.id}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => { return res.json() })
                    .then((data) => {
                        console.log(data);
                        if (data) {
                            resolve({
                                data: data

                            })
                        } else {
                            reject({
                                data: {}
                            })
                        }
                    }).catch((e) => {
                        reject({
                            data: {}
                        })
                    });
    });
}
export default {

    getList(resource, params) {
        if(resource === "Carrier") {
            if(params.dropDown) {
                return new Promise((resolve, reject) => {
                    getAll(resource,'OnlyUnUsedCarrier')
                    .then((response) => {
                        //console.log(response)
                        if (response) {
                            resolve(response);
                        } else {
                            reject(response)
                        }
                    })
                    .catch((e) => {
                        reject({
                            data: {}
                        })
                    });
                })   
            }
        }
        console.log(resource)
            return new Promise((resolve, reject) => {
                getAll(resource,resource)
                .then((response) => {
                    //console.log(response)
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            })
    },

    create(resource, params) {
        console.log(params)
        if (resource === 'NLP') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    nlpObj: {
                        _id: uuid.v4(),
                        name: params.data.name,
                        IP: params.data.IP,
                        Port: params.data.Port
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'MediaServer') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    MediaServerObj:
                    {
                        _id: uuid.v4(),
                        name: params.data.name,
                        fs_IP: params.data.fs_IP,
                        fs_Port: params.data.fs_Port,
                        password: params.data.password,
                        recordingPath: params.data.recordingPath,
                        audioStreamPort: params.data.audioStreamPort,
                        VoIPProfileName: params.data.VoIPProfileName,
                        asrManagerIP: params.data.asrManagerIP,
                        audioStreamURL: params.data.audioStreamURL,
                        WsProxyServerPort: params.data.WsProxyServerPort,
                        SIP_User: params.data.SIP_User,
                        SIP_IP: params.data.SIP_IP,
                        SIP_PORT: params.data.SIP_PORT,
                        outboundProxy: params.data.outboundProxy
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Mongo') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    MongoObj: {
                        _id: uuid.v4(),
                        name: params.data.name,
                        IP: params.data.IP,
                        Port: params.data.Port
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Elastic') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    ElasticObj: {
                        _id: uuid.v4(),
                        name: params.data.name,
                        domain: params.data.domain
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Customer') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    customerObj: {
                        apiConfig: {
                            mongodb: params.data.mongodb,
                            stanford: params.data.stanford,
                            elasticSearch: params.data.elasticSearch,
                            nlp: params.data.nlp,
                            mediaServer: params.data.mediaServer,
                            cloudServer: params.data.cloudServer,
                            redis: params.data.redis,
                            carrierOutbound: params.data.carrierOutbound,
                            carrierInbound: params.data.carrierInbound,
                            childProcess: params.data.childProcess
                        },
                        carrier: {
                            username: params.data.carrierData.username,
                            password: params.data.carrierData.password,
                            domain: params.data.carrierData.domain,
                            port: params.data.carrierData.port
                        },
                        _id: uuid.v4(),
                        name: params.data.name,
                        email: params.data.email,
                        phone: params.data.phone,
                        license: params.data.license,
                        ContactName: params.data.ContactName,
                        ContactEmail: params.data.ContactEmail,
                        ContactPhone: params.data.ContactPhone,
                        audioBroadcast: params.data.audioBroadcast, 
                        description: params.data.description,
                        webAccessDomain: params.data.webAccessDomain,
                        apiServerNginxRoute: params.data.apiServerNginxRoute,
                        apiIP: params.data.apiIP,
                        isCloud: params.data.isCloud
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'cloud') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    cloudMachineObj: {
                        _id: uuid.v4(),
                        name: params.data.name,
                        cloudAPIURL: params.data.cloudAPIURL,
                        cloudMEDIAURL: params.data.cloudMEDIAURL,
                        cloudAPIIP: params.data.cloudAPIIP,
                        cloudMediaIP: params.data.cloudMediaIP,
                        domain: params.data.domain
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Redis') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    redisObj: {
                        _id: uuid.v4(),
                        name: params.data.name,
                        IP: params.data.IP,
                        Port: params.data.Port
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if(resource === 'Carrier') {

            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    carrierObject: {
                        _id: uuid.v4(),
                        type:'Inbound',
                        name: params.data.name,
                        sourceIp: params.data.sourceIp,
                        phoneNumber: params.data.phoneNumber
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'PhoneMapping') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    PhoneMapping: {
                        _id: uuid.v4(),
                        carrierName: params.data.carrierName,
                        carrierPhone: params.data.carrierPhone,
                        customerName: params.data.customerName
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        }
    },

    getOne(resource, params) {
        if (resource === 'NLP') {
            return new Promise((resolve, reject) => {
                createAll(resource,resource,{
                    nlpObj: {
                        nlpId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'MediaServer') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    MediaServerObj: {
                        MediaServerId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Mongo') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    MongoObj: {
                        MongoId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Elastic') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    ElasticObj: {
                        ElasticId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Customer') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    customerObj: {
                        customerId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'cloud') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    cloudMachineObj: {
                        cloudMachineId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Redis') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    redisObj: {
                        redisId: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Carrier') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    carrierObject: {
                        _id: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        }  else if (resource === 'PhoneMapping') {
            return new Promise((resolve, reject) => {
                viewOneAll(resource,resource,{
                    PhoneMapping: {
                        _id: params.id
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        }
    },

    delete(resource, params) {
        return new Promise((resolve, reject) => {
            deleteAll(resource,resource,params)
            .then((response) => {
                //console.log(response)
                if (response) {
                    resolve(response);
                } else {
                    reject(response)
                }
            })
            .catch((e) => {
                reject({
                    data: {}
                })
            });
        })
    },

    update(resource, params) {
        if (resource === 'NLP') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    nlpObj: {
                        _id: params.id,
                        name: params.data.name,
                        IP: params.data.IP,
                        Port: params.data.Port
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'MediaServer') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    MediaServerObj:
                    {
                        _id: params.id,
                        name: params.data.name,
                        fs_IP: params.data.fs_IP,
                        fs_Port: params.data.fs_Port,
                        password: params.data.password,
                        recordingPath: params.data.recordingPath,
                        audioStreamPort: params.data.audioStreamPort,
                        VoIPProfileName: params.data.VoIPProfileName,
                        asrManagerIP: params.data.asrManagerIP,
                        audioStreamURL: params.data.audioStreamURL,
                        WsProxyServerPort: params.data.WsProxyServerPort,
                        SIP_User: params.data.SIP_User,
                        SIP_IP: params.data.SIP_IP,
                        SIP_PORT: params.data.SIP_PORT,
                        outboundProxy: params.data.outboundProxy
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Mongo') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    MongoObj: {
                        _id: params.id,
                        name: params.data.name,
                        IP: params.data.IP,
                        Port: params.data.Port
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Elastic') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    ElasticObj: {
                        _id: params.id,
                        name: params.data.name,
                        domain: params.data.domain
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Customer') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    customerObj: {
                        apiConfig: {
                            mongodb: params.data.mongodb,
                            stanford: params.data.stanford,
                            elasticSearch: params.data.elasticSearch,
                            nlp: params.data.nlp,
                            mediaServer: params.data.mediaServer,
                            cloudServer: params.data.cloudServer,
                            redis: params.data.redis,
                            carrierOutbound: params.data.carrierOutbound,
                            carrierInbound: params.data.carrierInbound,
                            childProcess: params.data.childProcess
                        },
                        carrier: {
                            username: params.data.carrierData.username,
                            password: params.data.carrierData.password,
                            domain: params.data.carrierData.domain,
                            port: params.data.carrierData.port
                        },
                        _id: params.id,
                        name: params.data.name,
                        email: params.data.email,
                        phone: params.data.phone,
                        license: params.data.license,
                        ContactName: params.data.ContactName,
                        ContactEmail: params.data.ContactEmail,
                        audioBroadcast: params.data.audioBroadcast, 
                        ContactPhone: params.data.ContactPhone,
                        description: params.data.description,
                        webAccessDomain: params.data.webAccessDomain,
                        apiServerNginxRoute: params.data.apiServerNginxRoute,
                        apiIP: params.data.apiIP,
                        isCloud: params.data.isCloud
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'cloud') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    cloudMachineObj: {
                        _id: params.id,
                        name: params.data.name,
                        cloudAPIURL: params.data.cloudAPIURL,
                        cloudMEDIAURL: params.data.cloudMEDIAURL,
                        cloudAPIIP: params.data.cloudAPIIP,
                        cloudMediaIP: params.data.cloudMediaIP,
                        domain: params.data.domain
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'Redis') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    redisObj: {
                        _id: params.id,
                        name: params.data.name,
                        IP: params.data.IP,
                        Port: params.data.Port
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if(resource === 'Carrier') {

            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    carrierObject: {
                        _id: params.id,
                        type:'Inbound',
                        name: params.data.name,
                        sourceIp: params.data.sourceIp,
                        phoneNumber: params.data.phoneNumber
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        } else if (resource === 'PhoneMapping') {
            return new Promise((resolve, reject) => {
                updateAll(resource,resource,{
                    PhoneMapping: {
                        _id: params.id,
                        carrierName: params.data.carrierName,
                        carrierPhone: params.data.carrierPhone,
                        customerName: params.data.customerName
                    }
                })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response)
                    }
                })
                .catch((e) => {
                    reject({
                        data: {}
                    })
                });
            });
        }
    },
    
    deleteMany (resource, params) {        
        return new Promise((resolve, reject) => {
            params.ids.forEach(e => {
                    deleteAll(resource,resource,{id:e})
                    .then((response) => {
                        //console.log(response)
                        if (response) {
                            resolve(response);
                        } else {
                            reject(response)
                        }
                    })
                    .catch((e) => {
                        reject({
                            data: {}
                        })
                    });
                })
            })
        
    }

    //     getMany: (resource, params) => {
    //         const query = {
    //             filter: JSON.stringify({ id: params.ids }),
    //         };
    //         const url = `${apiUrl}/${resource}?${stringify(query)}`;
    //         return httpClient(url).then(({ json }) => ({ data: json }));
    //     },

    //     getManyReference: (resource, params) => {
    //         const { page, perPage } = params.pagination;
    //         const { field, order } = params.sort;
    //         const query = {
    //             sort: JSON.stringify([field, order]),
    //             range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //             filter: JSON.stringify({
    //                 ...params.filter,
    //                 [params.target]: params.id,
    //             }),
    //         };
    //         const url = `${apiUrl}/${resource}?${stringify(query)}`;

    //         return httpClient(url).then(({ headers, json }) => ({
    //             data: json,
    //             total: parseInt(headers.get('content-range').split('/').pop(), 10),
    //         }));
    //     },



    //     updateMany: (resource, params) => {
    //         const query = {
    //             filter: JSON.stringify({ id: params.ids}),
    //         };
    //         return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    //             method: 'PUT',
    //             body: JSON.stringify(params.data),
    //         }).then(({ json }) => ({ data: json }));
    //     },





};