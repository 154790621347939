const uname = "admin@slashrtc.com";
const upassword = "slash@123+";
export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        if(username === uname && password === upassword) {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            return Promise.resolve();
        } else {
            return Promise.reject('Wrong Password or Username');
        }
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        let localname = localStorage.getItem('username');
        let localpassword = localStorage.getItem('password');
        if(localname === uname && localpassword === upassword) {
            return Promise.resolve();
        } else {
            return Promise.reject('Wrong Password or Username');
        }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};